<template>
  <ValidationObserver ref="formValidator" v-slot="{ invalid, validate }">
    <b-form @submit.prevent="validate().then(save)">
      <b-col md="12">
        <label class="form-control-label">Out of Office message</label>
        <div class="d-flex mb-2">
          <base-button
            v-for="(shortcode, index) in shortcodes"
            :key="`shortcode_${index}`"
            :disabled="!outOfOfficeMessageEnabled"
            size="sm"
            type="outline-primary"
            @click="addShortcode(shortcode)"
          ><i class="ni ni-fat-add"></i>{{ shortcode }}
          </base-button>
        </div>
        <app-textarea
          v-if="outOfOfficeMessageEnabled"
          ref="messageBlock"
          v-model="message"
          class="template-input"
          rules="required"
        />
        <template v-if="!outOfOfficeMessageEnabled">
          <div
            class="app-border p-3 position-relative mb-3 active-element greyed-out">
            <div class="font-400 gray-600-color pre-md-text">{{ message.replace(/\\/g, '') }}</div>
          </div>
        </template>
        <p :class="{'text-danger' : message.length > messageLimit}"
           class="d-flex justify-content-end">{{ messageInstruction }}</p>
        <div class="d-flex justify-content-end">
          <button :disabled="!outOfOfficeMessageEnabled || !isMessageChanged" class="btn btn-outline-primary"
                  @click.prevent="cancelingEditing">
            Cancel
          </button>
          <button :disabled="invalid || !canSave" class="btn btn-primary">
            Save
          </button>
        </div>
      </b-col>
    </b-form>
  </ValidationObserver>
</template>
<script>
import {mapActions} from 'vuex';
import AppTextarea from '@/components/App/controls/AppTextarea.vue';
import {UPDATE_OUT_OF_OFFICE_MSG_REQUEST} from '@/store/storeActions';

export default {
  components: {AppTextarea},
  props: {
    outOfOfficeMessageEnabled: Boolean,
    outOfOfficeMessage: String,
  },
  data: function() {
    const shortcodes = [
      'FirstName',
      'LastName',
    ];
    return {
      id: '',
      message: this.outOfOfficeMessage || 'Hi {FirstName} I\'m out of the office today and will respond when I return.',
      shortcodes,
      messageLimit: 400,
      isMessageChanged: false,
    };
  },
  watch: {
    message: {
      handler: function(value) {
        this.message = value;
        this.isMessageChanged = this.message !== this.outOfOfficeMessage;
      },
      immediate: true,
    },
  },
  computed: {
    messageInstruction: function() {
      return this.message.length + '/' + this.messageLimit;
    },
    canSave: function() {
      return this.outOfOfficeMessageEnabled && this.message.length <= this.messageLimit && this.isMessageChanged;
    },
  },
  methods: {
    ...mapActions([
      UPDATE_OUT_OF_OFFICE_MSG_REQUEST,
    ]),
    addShortcode: function(shortcode) {
      if (this.outOfOfficeMessageEnabled) {
        let cursorPosition = 0;
        const {messageBlock} = this.$refs;
        if (!!messageBlock) {
          const {textarea} = messageBlock.$refs;
          textarea.focus();
          cursorPosition = textarea ? textarea.selectionStart : 0;
        }
        this.message = `${this.message.slice(0, cursorPosition)}{${shortcode}}${this.message.slice(cursorPosition)}`;
      }
    },
    cancelingEditing: function() {
      this.message = this.outOfOfficeMessage || '';
    },
    save: async function() {
      if (this.outOfOfficeMessageEnabled) {
        const payload = {
          outOfOfficeMessage: this.message,
          outOfOfficeMessageEnabled: this.outOfOfficeMessageEnabled,
        };
        this.isMessageChanged = false;
        this.UPDATE_OUT_OF_OFFICE_MSG_REQUEST({payload});
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/sass/argon.scss';

.template-input .form-control, .template-input.form-control {
  border-radius: 10px;
}

</style>
