<template>
  <div class="invite-member-modal">
    <modal
      :show="display"
      footerClasses="d-none"
      headerClasses="app-border-b align-items-center"
      v-on:close="displayToggle(false)"
    >
      <template v-slot:header>
        <h5 id="exampleModalLabel" class="modal-title">{{ activeTab.headerTitle }}</h5>
      </template>
      <template>
        <component :is="activeTab.component" @cancel="() => displayToggle(false)" @changeTab="changeTab"/>
      </template>
    </modal>
  </div>
</template>
<script>

import MainTab from './tabsComponents/MainTab.vue';
import AccountCancellation from './tabsComponents/AccountCancellation.vue';
import PlanCancellation from './tabsComponents/PlanCancellation.vue';
import tabs from './tabs';

export default {
  components: {
    MainTab,
    AccountCancellation,
    PlanCancellation,
  },
  data: () => ({
    display: false,
    activeTab: tabs.main,
  }),
  methods: {
    displayToggle: function(isDisplay) {
      this.display = isDisplay;
    },
    submit: function() {
    },
    changeTab(tab) {
      this.activeTab = tab;
    },
  },
};
</script>
