<template>
  <div>
    <ValidationProvider :name="name" :rules="rules" v-bind="$attrs">
      <b-form-group>
        <label v-if="label" class="form-control-label">{{ label }}</label>
        <b-form-textarea
          ref="textarea"
          v-model="text"
          :disabled="disabled"
          :name="name"
          :placeholder="placeholder"
          :rows="rows"
          :rules="rules"
        ></b-form-textarea>
      </b-form-group>
    </ValidationProvider>
  </div>
</template>
<script>

export default {
  props: {
    name: String,
    label: String,
    value: String,
    rules: String,
    placeholder: String,
    disabled: Boolean,
    rows: {
      type: Number,
      default: 3,
    },
  },
  computed: {
    text: {
      get: function() {
        return this.value;
      },
      set: function(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>
