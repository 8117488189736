<template>
  <div class="d-fdlex flex-column">
    <div class="mt-1 mb-5">Cancelling your plan will remove all texting functionality at the end of this
      &nbsp;billing cycle. You will still be able to see candidate&nbsp;
      information and text messages until you delete your account.
    </div>
    <div class="d-flex justify-content-end">
      <button
        class="btn btn-outline-primary border-0"
        @click.prevent="toMain">
        No, don’t cancel my plan
      </button>
      <button class="btn btn-danger" type="submit"
              @click.prevent="cancelPlan">
        Cancel my plan
      </button>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {CANCEL_PLAN_REQUEST} from '@/store/storeActions';
import tabs from '../tabs';

export default {
  data: () => ({
    selectedTab: null,
  }),
  computed: {
    ...mapGetters([
      'accountType',
    ]),
  },
  methods: {
    ...mapActions([
      CANCEL_PLAN_REQUEST,
    ]),
    toMain() {
      this.$emit('changeTab', tabs.main);
    },
    async cancelPlan() {
      await this.CANCEL_PLAN_REQUEST();
      this.$emit('cancel');
    },
  },
};
</script>
