<template>
  <div :style="containerStyle" class="d-flex align-items-center">
    <div class="month-switcher" @click="switchMonth({increase: false})"><i class="ni ni-bold-left"></i></div>
    <div class="month-switcher" @click="switchMonth({increase: true})"><i class="ni ni-bold-right"></i></div>
    <div class="font-weight-bold position-relative" @click="openPicker">{{ formattedDate }}
      <flat-picker
        ref="flatPickerRef"
        v-model="date"
        :config="flatPickerConfig"
        class="form-control datepicker hide">
      </flat-picker>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import {APP_DATE_FORMAT} from '@/util/config.js';
import flatPicker from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import MonthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/';
import 'flatpickr/dist/plugins/monthSelect/style.css';

export default {
  components: {
    flatPicker,
  },
  props: {
    value: String,
    containerStyle: Object,
  },
  data: () => ({
    val: null,
    flatPickerConfig: {
      allowInput: true,
      plugins: [new MonthSelectPlugin({shorthand: false})],
    },
    isFuture: true,
  }),
  computed: {
    date: {
      get() {
        return moment(this.value, APP_DATE_FORMAT).toDate();
      },
      set(value) {
        this.$emit('input', moment(value, 'MMMM YYYY').format(APP_DATE_FORMAT));
      },
    },
    formattedDate() {
      return moment(this.date).format('MMMM YYYY');
    },
  },
  methods: {
    switchMonth({increase}) {
      const momentObj = moment(this.date);
      if (increase && momentObj.isSame(moment(), 'month')) {
        return;
      }
      const dateObj = increase ?
        momentObj.startOf('month').add(1, 'months') :
        momentObj.startOf('month').subtract(1, 'months');
      this.date = dateObj.format('MMMM YYYY');
    },
    openPicker() {
      const flatPickrInstance = this.$refs.flatPickerRef.fp;
      flatPickrInstance && flatPickrInstance.open();
    },
  },
};
</script>

<style lang="scss" scoped>
.hide {
  visibility: hidden;
  width: 0;
  position: absolute;
  bottom: 0;
  left: 0;
}

.month-switcher {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  padding: 2px;
  border-radius: 3px;
  border: 1px solid;
  border-color: transparent;
  transition: all .35s ease-in-out;

  &:hover {
    border-color: #dee2e6;
    box-shadow: 1px 1px 7px #ddd;
  }
}
</style>
